import React, { useMemo } from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import { SmartActionTabsKey } from '@/models';
import { getQueryParam } from '@/utils/query';

const SmartActionsLoadable = Loadable({
  loader: () => import('@/sections/SmartActions'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
interface SmActionsTab {
  tab?: string;
}
const SmartActionsPage: React.FC = () => {
  const { tab = '' } = getQueryParam<SmActionsTab>();

  const pageTitle = useMemo(() => {
    if (tab === SmartActionTabsKey.SMART_ACTIONS) {
      return 'Smart actions';
    } else if (tab === SmartActionTabsKey.SMART_TRIGGERS) {
      return 'Smart triggers';
    }
  }, [tab]);
  return (
    <React.Fragment>
      <HeaderMetadata title={pageTitle} />
      <PrivateProvider>
        <SmartActionsLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default SmartActionsPage;
